import { defineStore } from "pinia";
import { 
  HEADER_TEAM_API_KEY, 
  HEADER_MEMBER_API_KEY,
  apiServerUrl, 
  AddOns} from "~/constants";

const state = {
    allAddOns: []
};

export const useAddOnStore = defineStore("addOnStore", {
    state: () => state,
    persist: {
      storage: persistedState.localStorage,
    },
    getters: {
      
    },
    actions: {
      setResource({ resource, value }) {
        this[resource] = value;
      },
      setResourceById({ resource, value, id }) {
        this[resource] = this[resource].map(resource => {
            if(resource.id == id) return value
            else return resource
        });
      },
      
      fetchAllAddOns(){
        return new Promise((resolve, reject) => {
            const globalStore = useGlobalStore();
            const apiKey = globalStore.$state?.currentTeam?.apiKey ?? "";
  
            useFetch(apiServerUrl() + "team/addon", {
              method: "GET",
              headers: {
                [HEADER_TEAM_API_KEY]: apiKey,
              },
            }).then((res) => {
              const addOns = res.data.value;
              const error = res.error.value;
              if (error) {
                  console.log("get add ons error : ", error);
                  reject(error);
              } else {
                console.log("fetched add ons data => ", addOns)
                this.setResource({
                  resource: "allAddOns",
                  value: addOns,
                });
                resolve(addOns);
              }
            }
          ).catch((e) => {
              reject(e);
            });
          });
      },
      toggleAddOnStatus({teamAddOnId, activeStatus}){
        return new Promise((resolve, reject) => {
            const globalStore = useGlobalStore();

            const apiKey = globalStore.$state?.currentTeam?.apiKey ?? "";
  
            useFetch(apiServerUrl() + "team/addon/" + teamAddOnId + "?active=" + activeStatus, {
              method: "GET",
              headers: {
                [HEADER_TEAM_API_KEY]: apiKey,
              },
            }).then((res) => {
              const newAddOn = res.data.value;
              const error = res.error.value;
              if (error) {
                  console.log("toggle add on error : ", error);
                  reject(error);
              } else {
                console.log("fetched new add on data => ", newAddOn)
                globalStore.updateAddOnById(newAddOn.id, newAddOn);
                resolve(newAddOn);
              }
            }
          ).catch((e) => {
              reject(e);
            });
          });
      },
      isAddOnEnabled({ entity }) {
        const store = useGlobalStore();
        const addOnsData = store.$state?.currentTeam?.addOns ?? [];
        console.log("Entity => ", entity, store.$state?.currentTeam)
        for(let addOnData of addOnsData){
          console.log("here data => ", addOnData.addOn.id, AddOns[entity], addOnData.active, AddOns)
          if(addOnData.addOn.id == entity && addOnData.active) return true
        }
        console.log("returing false")
        return false;
      },
     
    }
  }
);
